<template>
  <div class="content">
    <base-header class="pb-7">
      <div class="row align-items-center py-4 d-md-none">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">专馆报表</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--7">
      <card refs="l1" class="no-border-card" body-classes="pb-1" footer-classes="pb-2">
        <div class="row mb-3">
          <div class="col d-md-flex">
            <div class="mr-md-1 my-1 d-flex">
              <el-date-picker v-model="filters.start" type="month" class="mr-1" placeholder="起始月份"
                format="yyyy-MM" value-format="yyyy-MM">
              </el-date-picker>
              <el-date-picker v-model="filters.end" type="month" class="ml-1" placeholder="结束月份" 
                format="yyyy-MM" value-format="yyyy-MM">
              </el-date-picker>
            </div>
            <div class="mx-md-1 my-1">
              <el-button type="success" @click="fetchData(false)" :loading="loading" class="fw">{{ this.loading ? "加载中..." : "查询" }}</el-button>
            </div>
            <div class="ml-md-2 my-1">
                <el-button type="info" @click="exportData" :loading="exporting" class="fw">导出</el-button>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <el-table refs="l2" row-key="id" header-row-class-name="thead-light" style="width: 100%" v-loading="loading"
              :data="tableData" stripe>
              <el-table-column prop="location" label="地区" class-name="pl-2"></el-table-column>
              <el-table-column prop="quantity" label="销售数量(吨)">
                <template slot-scope="{row}">
                  {{ row.quantity.toFixed(2) }}
                </template>
              </el-table-column>
              <el-table-column prop="amount" label="销售金额(万元)">
                <template slot-scope="{row}">
                  {{ row.amount.toFixed(2) }}
                </template>
              </el-table-column>
              <el-table-column prop="fupin_amount" label="扶贫销售金额(万元)">
                <template slot-scope="{row}">
                  {{ row.fupin_amount.toFixed(2) }}
                </template>
              </el-table-column>
              <el-table-column prop="fupin_population" label="带动贫困人口"></el-table-column>
            </el-table>
          </div>
        </div>
      </card>
    </div>
  </div>
</template>
<script>
/* eslint-disable */ 
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
//import moment from "moment";

export default {
  components: {
    RouteBreadCrumb,
    'store-report': () => import('../Stores/Widgets/StoreReport')
  },
  data() {
    return {
      loading: false,
      exporting: false,
      filters: { start: '', end: '', limit: 20, page: 1 },
      tableData: [],
      sums: null
    }
  },
  computed: {
    isSuper() {
      return this.$store.state.user.user && this.$store.state.user.user.is_super
    }
  },
  methods: {
    fetchData(isExport) {
      if(!isExport) this.loading = true;
      else this.exporting = true;
      var $this =this;
      this.axios.get("onlinestorereports", { params: { ...this.filters, type: isExport?'xlsx':'' }, responseType: isExport?'blob':'application/json' })
      .then((res) => {
        if(!isExport) {
            if (res.data.errcode == 0) {
                this.tableData = res.data.data;
            }
            else {
                this.$notifyErr('未能成功加载数据', res.data.errmsg);
            }
            this.loading = false;
        }
        else {
          var fileURL = window.URL.createObjectURL(new Blob([res.data]));
          var fileLink = document.createElement('a');

          fileLink.href = fileURL;
          var start = moment($this.filters.start).format('YYYYMM'), end = moment($this.filters.end).format('YYYYMM')
          fileLink.setAttribute('download', `线上专馆报表-${start}-${end}.${new Date().valueOf()}.xlsx`);
          document.body.appendChild(fileLink);
          fileLink.click();
          this.exporting = false;
        }
      })
      .catch((e) => {
        console.error(e);
        this.loading = false;
      })
    },
    exportData() {
      this.fetchData(true)
    }
  },
  created() {
    this.filters.start = new Date(moment().subtract(6, 'months'))
    this.filters.end = new Date()
    setTimeout(this.fetchData, 500)
  }
};
</script>
<style>
.no-border-card .card-footer { border-top: 0; }
.filters>div { margin-bottom:15px; }
.filters .search.el-input { width: 230px; }
.filters .el-select { width: 160px }
.cell.action-col-header {
    display: block !important;
    text-align: center;
    padding-top: 9px !important;
}
.bg-amount {
    background-color: rgb(251, 252, 235) !important;
}
.el-table .cell { padding: 0; }
.el-table__footer .cell { font-weight: bold; }
div.el-table tbody td {
    padding: 0.5rem;
}
td.el-table__expanded-cell, td.el-table__expanded-cell:hover {
  background-color: #f8f8f8 !important;
}
.filters .fw, .fw.el-input, .fw.el-select, .el-button.fw {
  width: 100% !important;
}
</style>
