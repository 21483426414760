<template>
  <div>
    <!-- Header -->
    <div class="header bg-gradient-success py-7">
      <div class="container">
        <div class="header-body text-center mb-5">
          <div class="row justify-content-center">
            <div class="col-xl-5 col-lg-6 col-md-8 px-5">
              <h1 class="text-white">广州帮扶联盟乡村振兴<br/>数据采集系统</h1>
              <p class="text-lead text-white ls-2">账号注册</p>
            </div>
          </div>
        </div>
      </div>
      <div class="separator separator-bottom separator-skew zindex-100">
        <svg x="0" y="0" viewBox="0 0 2560 100" preserveAspectRatio="none" version="1.1" xmlns="http://www.w3.org/2000/svg">
          <polygon class="fill-default" points="2560 0 2560 100 0 100"></polygon>
        </svg>
      </div>
    </div>
    <!-- Page content -->
    <div class="container mt--8">
      <!-- Table --> 
      <div class="row justify-content-center">
        <div class="col-lg-6 col-md-8">
          <div class="card bg-secondary border-0">
            <div class="card-body px-lg-5">
              <div class="text-center text-muted mb-4">
                <small class="ls-2">请填写账号资料</small>
              </div>
              <el-form ref="regform" :model="model" label-width="auto" label-position="top" v-loading="loading">
                <div class="row">
                  <div class="col-12">
                    <el-form-item prop="username" :rules="[{ validator: checkUsername, trigger: 'blur' }]">
                      <el-input v-model="model.username" placeholder="账号名称" prefix-icon="el-icon-user"></el-input>
                    </el-form-item>
                  </div>
                  <div class="col-12">
                    <el-form-item prop="password" :rules="[
                        {required:true, message:'请填写登录密码,6位以上'},
                        { min:6, message:'登录密码至少6位长度'}
                      ]">
                      <el-input type="password" v-model="model.password" placeholder="登录密码" prefix-icon="el-icon-key"></el-input>
                    </el-form-item>
                  </div>
                </div>
                <div class="mt-n3 mb-3 text-muted font-italic" v-show="showPwdStrength">
                  <small>密码强度: <span class="font-weight-700" :class="pwdStrengthColor">{{ pwdStrength }}</span></small>
                </div>
                <div class="row">
                  <div class="col-12">
                    <el-form-item prop="company" :rules="[{required:true, message:'请填写公司名称'}]">
                      <el-input v-model="model.company" placeholder="公司名称" prefix-icon="el-icon-office-building"></el-input>
                    </el-form-item>
                  </div>
                </div>
                <el-divider>负责人</el-divider>
                <div class="row">
                  <div class="col-5 pr-1">
                    <el-form-item prop="headusername">
                      <el-input v-model="model.headusername" placeholder="名称"></el-input>
                    </el-form-item>
                  </div>
                  <div class="col-7 pl-1">
                    <el-form-item prop="headuserphone">
                      <el-input type="number" v-model="model.headuserphone" placeholder="联系电话"></el-input>
                    </el-form-item>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <el-form-item prop="headuseremail">
                      <el-input v-model="model.headuseremail" placeholder="邮箱地址" required></el-input>
                    </el-form-item>
                  </div>
                </div>
                <el-divider>主要联系人</el-divider>
                <div class="row">
                  <div class="col-5 pr-1">
                    <el-form-item prop="mainusername">
                      <el-input v-model="model.mainusername" placeholder="名称"></el-input>
                    </el-form-item>
                  </div>
                  <div class="col-7 pl-1">
                    <el-form-item prop="mainuserphone">
                      <el-input type="number" v-model="model.mainuserphone" placeholder="联系电话"></el-input>
                    </el-form-item>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <el-form-item prop="mainuseremail">
                      <el-input v-model="model.mainuseremail" placeholder="邮箱地址" required></el-input>
                    </el-form-item>
                  </div>
                </div>

                <!-- <div class="form-group">
                  <div class="input-group input-group-alternative">
                    <div class="input-group-prepend" title="账号类型">
                      <span class="input-group-text">
                        <i class="ni ni-pin-3"></i>
                      </span>
                    </div>
                    <div class="form-control d-flex">
                      <base-radio name="counter" v-model="model.type">专柜</base-radio>
                      <base-radio name="store" class="ml-3" v-model="model.type">专馆</base-radio>
                      <base-radio name="region" class="ml-3" v-model="model.type">专区</base-radio>
                    </div>
                  </div>
                </div> -->
                
                <div class="text-center">
                  <button type="button" class="btn btn-primary mt-4 ls-3" @click="submit">注册</button>
                </div>
              </el-form>
            </div>
          </div>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col text-center">
          <router-link to="/login" class="nav-link"><span class="nav-link-inner--text">登录</span></router-link>
        </div>
      </div>
      <modal :show.sync="modal" @close="leave">
          <h6 slot="header" class="modal-title" id="modal-title-default">提交成功</h6>
          <p>还差一步...</p>
          <p>我们已经收到您的注册申请，请等待管理员审核并激活账号。</p>
          <template slot="footer">
              <base-button type="primary" @click="leave">知道了</base-button>
          </template>
      </modal>
    </div>
  </div>
</template>
<script>
  //import { Divider } from 'element-ui';
  const PATTERN_PWD_STRENGTH_H = /(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=([\x21-\x7e]+)[^a-zA-Z0-9]).{8,18}/;
  const PATTERN_PWD_STRENGTH_M = /(?=.*[0-9])(?=.*[a-zA-Z])(?=([\x21-\x7e]+)[^a-zA-Z0-9]).{8,18}/;
  const PATTERN_PWD_STRENGTH_L = /[0-9a-zA-z_]{8,18}/;

  export default {
    // components: {
    //   [Divider.name]: Divider
    // },
    name: 'register',
    data() {
      return {
        loading: false,
        modal: false,
        model: {
          username: '',
          password: '',
          company: '',
          headusername: '',
          headuserphone: '',
          headuseremail: '',
          mainusername: '',
          mainuserphone: '',
          mainuseremail: ''
        },
        pwdStrength: '',
        pwdStrengthColor: ''
      }
    },
    computed: {
      showPwdStrength() {
        return this.pwdStrength != ''
      }
    },
    watch: {
      'model.password'(v) {
        if(PATTERN_PWD_STRENGTH_H.test(v)) {
          this.pwdStrength = '强', this.pwdStrengthColor = 'text-success'
        } else if(PATTERN_PWD_STRENGTH_M.test(v)) {
          this.pwdStrength = '中等', this.pwdStrengthColor = 'text-warning'
        } else if(PATTERN_PWD_STRENGTH_L.test(v)) {
          this.pwdStrength = '弱', this.pwdStrengthColor = 'text-danger'
        } else {
          this.pwdStrength = ''
        }
      }
    },
    methods: {
      checkUsername(rule, value, callback) {
        if (!value) {
          return callback(new Error('请输入账号名称'));
        }
        if(!/^[a-zA-Z0-9_]{4,16}$/.test(value)) {
          return callback(new Error('账号名称至少4位长度，不能包含特殊字符'));
        }
        this.axios.get('users/exists', { params: {username: value} }).then((res) => {
          if (res.data.errcode == 0) {
            if(res.data.usernameExists) {
              callback(new Error('账号名称已被使用，请输入其他名称'));
            }
            else {
              callback()
            }
          }
          else {
            callback()
          }
        })
      },
      submit() {
        this.$refs['regform'].validate((valid) => {
          if (valid) {
            this.loading = true;
            this.axios.get('users/create', {params: this.model}).then((res) => {
              if (res.data.errcode == 0) {
                this.modal = true;
              }
              this.loading = false;
            }).catch((err) => {
                if(err.response) {
                  if(err.response.data.error) this.$notify({type:'warning', message: err.response.data.error.message});
                  this.$notify({type:'warning', message: err.response.data[0].message});
                }
                else this.$notify({type:'warning', message: 'Failed: ' + err.message});
                this.loading = false;
            })
          } else {
            console.log('form invalid')
            return false;
          }
        });
      },
      leave() {
        this.$router.push('/login')
      }
    }

  };
</script>
<style scoped>
  .el-divider__text {
    background-color: #f7fafc;
    color: #8898aa;
    font-weight: 300;
    letter-spacing: 0.125rem;
  }
  .el-input, .el-select, .el-autocomplete {
        width: 100%;
    }
</style>
