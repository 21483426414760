<template>
  <div class="content">
    <base-header class="pb-7">
      <div class="row align-items-center py-4 d-md-none">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">三专统计</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--7">
      <card refs="l1" class="no-border-card">
        <div class="row mb-3">
          <div class="col d-md-flex">
            <div class="d-md-flex flex-grow-1">
              <div class="mr-md-1 my-1">
                <el-input class="search" v-model="filters.year" placeholder="输入年份, 如'2020'" prefix-icon="el-icon-search" clearable></el-input>
              </div>
              <div class="mx-md-1 my-1">
                <el-button type="success" @click="fetchData(false)" :loading="loading" class="fw">{{ this.loading ? "加载中..." : "查询" }}</el-button>
              </div>
            </div>
            <div>
              <div class="my-1">
                <el-button type="info" @click="exportData" class="fw">导出</el-button>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <el-table refs="l2" row-key="id" header-row-class-name="thead-light" header-cell-class-name="align-center" style="width: 100%" v-loading="loading"
              :data="tableData" stripe show-summary>
              <el-table-column prop="district" label="区域" class-name="pl-2" min-width="120"></el-table-column>
              <el-table-column label="专区开设数量">
                <el-table-column prop="area_count" label="计划"></el-table-column>
                <el-table-column prop="area_count_today" label="实际"></el-table-column>
              </el-table-column>
              <el-table-column label="专柜开设数量">
                <el-table-column prop="counter_count" label="计划"></el-table-column>
                <el-table-column prop="counter_count_today" label="实际"></el-table-column>
              </el-table-column>
              <el-table-column label="专馆开设数量">
                <el-table-column prop="store_count" label="计划"></el-table-column>
                <el-table-column prop="store_count_today" label="实际"></el-table-column>
              </el-table-column>
              <el-table-column prop="total_count_today" label="实际合计">
              </el-table-column>
              <el-table-column label="扶贫产品销售总额(万元)">
                <el-table-column prop="fupin_amount" label="今年计划">
                    <template slot-scope="{row}">
                        {{ row.fupin_amount.toFixed(2) }}
                    </template>
                </el-table-column>
                <el-table-column prop="fupin_amount_today" label="实际总额">
                    <template slot-scope="{row}">
                        {{ (row.fupin_amount_today).toFixed(2) }}
                    </template>
                </el-table-column>
              </el-table-column>
              <el-table-column label="排名">
                <template slot-scope="{row}">
                    {{ row.index }}
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </card>
    </div>
  </div>
</template>
<script>
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";

export default {
  components: {
    RouteBreadCrumb
  },
  data() {
    return {
      loading: false,
      exporting: false,
      filters: { year: new Date().getFullYear() },
      tableData: []
    }
  },
  watch: {
    newYear(v) {
      this.filters.year = v;
      this.fetchData();
    }
  },
  methods: {
    calcIndex(item, cankao) {
        var i = 1;
        cankao.forEach(x=>{
            if(item.fupin_amount_today<x.amount) i++;
        })
        return i;
    },
    fetchData(isexport) {
      if(isexport) this.exporting = true;
      else this.loading = true;
      this.axios.get("totalreports", { params: { ...this.filters, type: isexport ? "export" : '' }, responseType: isexport?'blob':'application/json' })
      .then((res) => {
        if(!isexport) {
          if (res.data.errcode == 0) {
            this.tableData = res.data.data.map(x=>{
                  return { 
                    ...x, 
                    fupin_amount_today: x.stores_fupin + x.areas_fupin + x.counters_fupin,
                    total_count_today: x.area_count_today + x.counter_count_today + x.store_count_today
                  }
              })
              var cankao = res.data.data.map(x=>{
                  return { id: x.id, amount: x.stores_fupin + x.areas_fupin + x.counters_fupin }
              })
              this.tableData.forEach(x=>{
                  x.index = this.calcIndex(x, cankao);
              })
            }
          else {
            this.$notifyErr('未能成功加载数据', res.data.errmsg);
          }
          this.loading = false;
        }
        else {
            var fileURL = window.URL.createObjectURL(new Blob([res.data]));
            var fileLink = document.createElement('a');

            fileLink.href = fileURL;
            fileLink.setAttribute('download', `三专统计-${this.filters.year}.${new Date().valueOf()}.xlsx`);
            document.body.appendChild(fileLink);
            fileLink.click();
            this.exporting = false;
          }
      })
      .catch((e) => {
        console.error(e);
        this.loading = false;
      })
    },
    exportData() {
      this.fetchData(true)
    }
  },
  created() {
    setTimeout(this.fetchData, 500)
  },
};
</script>
<style>
.no-border-card .card-footer { border-top: 0; }
.filters>div { margin-bottom:15px; }
.filters .search.el-input { width: 230px; }
.filters .el-select { width: 160px }
.card .table td, .card .table th, .card .el-table td, .card .el-table th {
    padding: 0.5rem;
}
.align-center .cell {
    text-align: center;
    display: block !important;
    padding: 0;
    line-height: 40px;
}
.filters .fw, .fw.el-input, .fw.el-select, .el-button.fw {
  width: 100% !important;
}
.el-table .cell, .el-table th div, .el-table--border td:first-child .cell, .el-table--border th:first-child .cell {
  padding-left:0
}
</style>
