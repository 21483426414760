<template>
  <div class="content">
    <base-header class="pb-7">
      <div class="row align-items-center py-4 d-md-none">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">专柜报表</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--7">
      <card refs="l1" class="no-border-card" body-classes="pb-1" footer-classes="pb-2">
        <div class="row mb-3">
          <div class="col d-md-flex">
            <div class="mr-md-1 my-1 d-flex">
              <el-date-picker v-model="filters.start" type="month" class="mr-1" placeholder="起始月份"
                format="yyyy-MM" value-format="yyyy-MM">
              </el-date-picker>
              <el-date-picker v-model="filters.end" type="month" class="ml-1" placeholder="结束月份" 
                format="yyyy-MM" value-format="yyyy-MM">
              </el-date-picker>
            </div>
            <div class="mx-md-1 my-1">
              <el-input class="search" v-model="filters.search" placeholder="输入关键字检索" prefix-icon="el-icon-search" clearable></el-input>
            </div>
            <div class="mx-md-1 my-1">
              <el-button type="success" @click="fetchData(false)" :loading="loading" class="fw">{{ this.loading ? "加载中..." : "查询" }}</el-button>
            </div>
            <div class="ml-md-2 my-1">
              <el-button type="info" @click="exportData" :loading="exporting" class="fw">导出</el-button>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <el-table refs="counterTable" row-key="id" header-row-class-name="thead-light" style="width: 100%" v-loading="loading"
              @expand-change="expandChange"
              :data="tableData" :summary-method="sum" show-summary stripe>
              <el-table-column type="expand" v-if="!isReportReader">
                <template slot-scope="{row}">
                  <div class="row d-flex px-md-3">
                    <div class="col-md-3 p-0">
                    <counter-report v-if="row.expanded" :key="'counter-'+row.id+'-monthly'" 
                      :editable="false" :insertable="false" :auto-height="true" table-class="bg-white"
                      :counterid="row.id">
                    </counter-report>
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="district" label="区域" class-name="pl-2" min-width="180"></el-table-column>
              <el-table-column prop="company_type" label="经营性质" width="120"></el-table-column>
              <el-table-column prop="company" label="经营主体" min-width="200"></el-table-column>
              <el-table-column prop="total_amount" label="总额(万元)" width="120" class-name="bg-amount"></el-table-column>
              <el-table-column prop="fupin_amount" label="扶贫额(万元)" width="120" class-name="bg-amount"></el-table-column>
              <el-table-column prop="device_type" label="专柜类型" width="120"></el-table-column>
              <el-table-column prop="address" label="经营地址/投放地点" min-width="200"></el-table-column>
              <el-table-column prop="device_producer" label="专柜生产厂家" min-width="180"></el-table-column>
              <el-table-column prop="tec_support" label="技术服务商联系方式" min-width="300"></el-table-column>
              <el-table-column prop="size" label="占地面积" width="100"></el-table-column>
              <el-table-column prop="quantity" label="专柜数量" width="100"></el-table-column>
              <el-table-column prop="product_type" label="扶贫产品类型" min-width="160"></el-table-column>
              <el-table-column prop="cashier_producer" label="收银系统厂商" min-width="160"></el-table-column>
              <el-table-column prop="bank_account" label="经营主体开户银行" min-width="180"></el-table-column>
              <el-table-column prop="payments" label="专柜支付方式" min-width="160"></el-table-column>
              <el-table-column prop="owner_name" label="联系人" min-width="110"></el-table-column>
              <el-table-column prop="owner_phone" label="联系电话" width="140"></el-table-column>
              <el-table-column prop="remark" label="备注" min-width="100"></el-table-column>
            </el-table>
          </div>
        </div>
        <div slot="footer" class="col-12 px-0 d-flex justify-content-center justify-content-sm-between flex-wrap">
          <el-pagination class="pb-3 mx--3" background layout="sizes, prev, pager, next"
            :page-sizes="pageSizeOptions" :page-size="filters.limit"
            :total="total" :current-page="filters.page"
            @size-change="pageSizeChange" @current-change="pageIndexChange" @prev-click="movePrev" @next-click="moveNext">
          </el-pagination>
        </div>
      </card>
    </div>
  </div>
</template>
<script>
/* eslint-disable */ 
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
//import moment from "moment";

export default {
  components: {
    RouteBreadCrumb,
    'counter-report': () => import('../Stores/Widgets/CounterReport')
  },
  data() {
    return {
      loading: false,
      exporting: false,
      filters: { search: '', start: '', end: '', limit: 10, page: 1 },
      tableData: [],
      sums: null,
      pageSizeOptions: [10, 20, 50, 100],
      total: 0,
    }
  },
  computed: {
    isSuper() {
      return this.$store.state.user.user && this.$store.state.user.user.is_super
    }
  },
  methods: {
    fetchData(isExport) {
      if(!isExport) this.loading = true;
      else this.exporting = true;
      var $this =this;
      this.axios.get("countersreports", { params: { ...this.filters, type: isExport?'xlsx':'' }, responseType: isExport?'blob':'application/json' })
      .then((res) => {
        if(!isExport) {
            if (res.data.errcode == 0) {
                this.tableData = res.data.data.data.map(item => { item.updating=false; item.expanded = false; return item; });
                this.total = res.data.data.total;
                this.filters.page = res.data.data.page;
                this.sums = res.data.sum;
            }
            else {
                this.$notifyErr('未能成功加载数据', res.data.errmsg);
            }
            this.loading = false;
        }
        else {
          var fileURL = window.URL.createObjectURL(new Blob([res.data]));
          var fileLink = document.createElement('a');

          fileLink.href = fileURL;
          var start = moment($this.filters.start).format('YYYYMM'), end = moment($this.filters.end).format('YYYYMM')
          fileLink.setAttribute('download', `专柜报表-${start}-${end}.${new Date().valueOf()}.xlsx`);
          document.body.appendChild(fileLink);
          fileLink.click();
          this.exporting = false;
        }
      })
      .catch((e) => {
        console.error(e);
        this.loading = false;
      })
    },
    sum(params) {
        const { columns } = params;
        const sums = [];
        const $this = this;
        columns.forEach((column, index) => {
          if($this.sums) {
            if (column.property === 'total_amount') {
                sums[index] = $this.sums[0].total_amount;
            } 
            else if (column.property === 'fupin_amount') {
                sums[index] = $this.sums[0].fupin_amount;
            }
            else {
                sums[index] = '';
            }
          }
          else {
            sums[index] = '';
            }
        });

        return sums;
    },
    pageSizeChange(v) { this.filters.limit = v; this.fetchData() },
    pageIndexChange(v) { this.filters.page = v; this.fetchData() },
    movePrev() { this.filters.page --; this.fetchData() },
    moveNext() { this.filters.page ++; this.fetchData() },
    exportData() {
      this.fetchData(true)
    },
    expandChange(row) {
      row.expanded = !row.expanded
    }
  },
  created() {
    this.filters.start = new Date(moment().subtract(6, 'months'))
    this.filters.end = new Date()
    setTimeout(this.fetchData, 500)
  }
};
</script>
<style>
.no-border-card .card-footer { border-top: 0; }
.filters>div { margin-bottom:15px; }
.filters .search.el-input { width: 230px; }
.filters .el-select { width: 160px }
.cell.action-col-header {
    display: block !important;
    text-align: center;
    padding-top: 9px !important;
}
.bg-amount {
    background-color: rgb(251, 252, 235) !important;
}
.el-table .cell { padding: 0; }
.el-table__footer .cell { font-weight: bold; }
div.el-table tbody td {
    padding: 0.5rem;
}
.filters .fw, .fw.el-input, .fw.el-select, .el-button.fw {
  width: 100% !important;
}
</style>
